import MKBox from "../../components/MKBox";
import footerRoutes from "../../footer.routes";
import CenteredFooter from "./components/CenteredFooter";

export default function Footer() {
    return <>
        <MKBox pt={6} px={1} mt={6}>
            <CenteredFooter />
        </MKBox>
    </>;
}
