import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import GridContainer from "./components/GridContainer";

function ValueProposition({lang, thisId, nextId}) {
    return (

        <GridContainer lang={lang} thisId={thisId} nextId={nextId}>
            <Grid container item xs={12} justifyContent="center" className={"section"}>
                {
                    lang.getTranslation(thisId, "items").map((d, i) => (
                        <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{ml: {xs: 0, md: "auto"}, mr: {xs: 0, md: 6}, mb: {xs: 4, md: 0}}}
                        >
                            <Stack spacing={{xs: 4, md: 8}} style={{minHeight: "100px", marginBottom: "32px"}}>
                                <SimpleInfoCard
                                    icon="payment"
                                    title={d.title}
                                    description={d.text}
                                />
                            </Stack>
                        </Grid>
                    ))
                }
            </Grid>
        </GridContainer>
    );
}

export default ValueProposition;
