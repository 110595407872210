import {Helmet} from "react-helmet";
import {Header} from "./header";
import ValueProposition from "./value-proposition";
import {Navigation} from "./navigation";
import Footer from "./footer";
import Pricing from "./pricing";
import ProductOverview from "./product-overview";


function Home({lang}) {

    return (
        <>
            <Helmet htmlAttributes={{lang: lang.getLanguage()}}>
                <title>{lang.getTranslation("title")}</title>
                <meta charSet="UTF-8"/>
                <meta httpEquiv="content-language" content="de-DE"/>
                <meta name="viewport" content="width=600"/>
                <meta name="description" content={lang.getTranslation("description")}/>
            </Helmet>
            <Navigation thisId="header" nextId="how-it-works" lang={lang}/>
            <Header thisId="header" nextId="product" lang={lang}/>
            <ProductOverview thisId="product" nextId="value" lang={lang}/>
            <ValueProposition thisId="value" nextId="pricing" lang={lang}/>
            <Pricing thisId="pricing" lang={lang}/>
            <Footer/>
        </>
    );
}

export default Home;
