/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.codexanalytica.com/product/material-kit-pro-react
 * Copyright 2023 Creative Tim (https://www.codexanalytica.com)

 Coded by www.codexanalytica.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function CenteredFooter({company, links, socials, light}) {
    const {href, name} = company;

    const year = new Date().getFullYear();

    const renderLinks = links.map((link) => (
        <MKTypography
            key={link.name}
            component={Link}
            href={link.href}
            variant="body2"
            color={light ? "white" : "secondary"}
            fontWeight="regular"
        >
            {link.name}
        </MKTypography>
    ));

    const renderSocials = socials.map((social) => (
        <MKTypography
            key={social.link}
            component={Link}
            href={social.link}
            variant="body2"
            color={light ? "white" : "secondary"}
            fontWeight="regular"
        >
            {social.icon}
        </MKTypography>
    ));

    return (
        <MKBox component="footer" py={6}>
            <Grid container justifyContent="center">
                {
                    /*
                <Grid item xs={10} lg={8}>
                    <Stack
                        direction="row"
                        flexWrap="wrap"
                        justifyContent="center"
                        spacing={{xs: 2, lg: 3, xl: 6}}
                        mb={3}
                    >
                        {renderLinks}
                    </Stack>
                </Grid>
                     */
                }
                {
                    /*
                    <Grid item xs={12} lg={8}>
                        <Stack display="flex" direction="row" justifyContent="center" spacing={3} mt={1} mb={3}>
                            {renderSocials}
                        </Stack>
                    </Grid>
                    */
                }
                <Grid item xs={12} lg={8} sx={{textAlign: "center"}}>
                    <MKTypography variant="body2" color={light ? "white" : "secondary"}>
                        Copyright &copy; {year} by{" "}
                        <MKTypography
                            component={Link}
                            href={href}
                            target="_blank"
                            rel="noreferrer"
                            variant="body2"
                            color={light ? "white" : "secondary"}
                        >
                            {name}
                        </MKTypography>
                        .
                    </MKTypography>
                </Grid>
            </Grid>
        </MKBox>
    );
}

// Setting default values for the props of CenteredFooter
CenteredFooter.defaultProps = {
    company: {href: "https://www.codexanalytica.com/", name: "The Famous Cat Ltd"},
    links: [
        {href: "https://www.codexanalytica.com/", name: "Company"},
        {href: "https://www.codexanalytica.com/aboutus", name: "About Us"},
    ],
    socials: [
        {icon: <FacebookIcon fontSize="small"/>, link: "https://www.facebook.com/codexanalytica/"},
        {
            icon: <TwitterIcon fontSize="small"/>,
            link: "https://twitter.com/codexanalytica",
        },
        {
            icon: <LinkedInIcon fontSize="small"/>,
            link: "https://www.linkedin.com/codexanalytica/",
        },
        {icon: <GitHubIcon fontSize="small"/>, link: "https://github.com/codexanalytica"},
    ],
    light: false,
};

// Typechecking props for the CenteredFooter
CenteredFooter.propTypes = {
    company: PropTypes.objectOf(PropTypes.string),
    links: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
    socials: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
    light: PropTypes.bool,
};

export default CenteredFooter;
