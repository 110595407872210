import React from "react";
import MKButton from "./MKButton";

export const NextSection = ({ lang, thisId, nextId }) => {
  if (!nextId) {
    return <></>;
  }
  return <>
    <MKButton className="next-section-button" variant="gradient" color="primary" size="large" href={"#" + nextId}
              onClick={(e) => document.body.classList.remove("navigation-visible")}>
      {lang.getTranslation(nextId, "step")}
    </MKButton>
  </>;

};
