// @mui icons
// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCA from "assets/images/codex/logo-transparent-cut-small-h70.png";

const date = new Date().getFullYear();

export default {
    brand: {
        name: "Codex Analytica",
        image: logoCA,
        route: "/",
    },
    company: {
        name: "Codex Analytica",
        image: logoCA,
        href: "/",
    },
    socials: [],
    menus: [
        {
            name: "company",
            items: [
                {name: "about us", href: "#"},
                {name: "freebies", href: "#"},
                {name: "premium tools", href: "#"},
                {name: "blog", href: "#"},
            ],
        },
    ],
    copyright: (
        <MKTypography variant="button" fontWeight="regular">
            All rights reserved. Copyright &copy; {date} Codex Analytica by{" "}
            <MKTypography
                component="a"
                href="https://www.codexanalytica.com"
                target="_blank"
                rel="noreferrer"
                variant="button"
                fontWeight="regular"
            >
                The Famous Cat Ltd.
            </MKTypography>
        </MKTypography>
    ),
};
