import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import bgPattern from "../../../assets/images/shapes/pattern-lines.svg";
import {NextSection} from "../../../components/next-section";

function Pricing({lang, thisId, nextId, children}) {
    return (
        <>
            <div id={thisId} className="section-title">
                <h2>{lang.getTranslation(thisId, "title")}</h2>
            </div>
            <MKBox className="section-box" component="section" py={{xs: 6, md: 12}}>
                <Container>
                    <div className="section-description">
                    </div>
                    <Grid container item justifyContent="center" mx="auto"
                          className={"next-section-grid"}>
                        <MKBox style={{marginTop: "32px"}} position="relative" variant="gradient" bgColor="info"
                               mt={{xs: 0, lg: 12}} mx={-2} className={"rounded-borders"}>
                            <MKBox
                                component="img"
                                src={bgPattern}
                                alt="background-pattern"
                                position="absolute"
                                top={0}
                                left={0}
                                width={{xs: "100%", lg: "100%"}}
                                height={{xs: "100%", lg: "100%"}}
                                opacity={0.6}
                            />
                            <Container>
                                <Grid container justifyContent="center" className="inner-content-container" center
                                      sx={{pt: 6, pb: 18}}>
                                    {children}
                                </Grid>
                            </Container>
                        </MKBox>
                    </Grid>
                    <Grid container item justifyContent="center" mx="auto"
                          className={"next-section-grid"}>
                        <NextSection lang={lang} thisId={thisId} nextId={nextId}/>
                    </Grid>
                </Container>
            </MKBox>
        </>
    );
}

export default Pricing;
