import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton";
import GridContainer from "./components/GridContainer";

function Pricing({lang, thisId, nextId}) {
    return (
        <GridContainer lang={lang} thisId={thisId} nextId={nextId}>
            <Grid item xs={12} md={7} sx={{textAlign: "center"}}>
                <MKTypography variant="h3" color="white" mb={1}>
                    Ready to automate your code reviews?
                </MKTypography>
                <MKTypography variant="body2" color="white">
                    <p>
                        We are in a very early stage and would love to get your feedback.
                        So the use of Codex Analytica is <b>completely free</b>.
                    </p>
                    <br/>
                    <p>
                        We may apply some usage caps though, since operating expenses should
                        remain within reasonable limits.
                    </p>
                </MKTypography>

                <MKBox mt={3}>
                    <MKButton
                        variant={"gradient"}
                        size="large"
                        fullWidth
                    >
                        Start now for free
                    </MKButton>
                </MKBox>
            </Grid>
        </GridContainer>
    );
}

export default Pricing;
