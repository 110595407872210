import colors from "assets/theme/base/colors";

const {info, dark} = colors;

export default {
    html: {
        scrollBehavior: "smooth",
    },
    "*, *::before, *::after": {
        margin: 0,
        padding: 0,
    },
    "a, a:link, a:visited": {
        textDecoration: "none !important",
    },
    "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
        color: `${dark.main} !important`,
        transition: "color 150ms ease-in !important",
    },
    "a.link:hover, .link:hover, a.link:focus, .link:focus": {
        color: `${info.main} !important`,
    },

    ".section-title": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "12px",
        marginTop: "32px",
    },
    ".section-title h2": {
        zIndex: "200",
        color: "#344767",
    },
    ".section-description": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "12px",
    },
    ".section-description h3": {
        zIndex: "200",
        fontSize: "26px",
        color: "#344767",
    },
    ".MuiBox-root": {
        paddingTop: "0 !important",
    },
    ".section-box .MuiContainer-root": {
        minHeight: Math.floor(window.innerHeight * 0.66) + "px"
    },
    ".section": {
        paddingTop: "20px",
    },
    "#product-container .next-section-grid": {
        marginTop: "64px !important",
    },
    ".next-section-grid": {
        marginTop: "-32px !important",
    },
    ".rounded-borders": {
        background: "#191919 !important",
        borderRadius: "0.75rem",
        margin: "4%"
    },
    ".inner-content-container": {
        padding: "72px !important",
    },
    ".MuiButton-root.next-section-button": {
        marginTop: "16px"
    }
};
