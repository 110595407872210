import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import MKTypography from "components/MKTypography";
import AboutUsOption from "./components/AboutUsOption";
import GridContainer from "./components/GridContainer";

function ProductOverview({lang, thisId, nextId}) {
    return (
        <GridContainer lang={lang} thisId={thisId} nextId={nextId}>
            <Grid container alignItems="center">
                <Grid item xs={12} lg={5}>
                    <MKTypography variant="h3" my={1}>
                        {lang.getTranslation(thisId, "intro-claim")}
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2}>
                        {lang.getTranslation(thisId, "intro")}
                    </MKTypography>

                </Grid>
                <Grid item xs={12} lg={6} sx={{ml: {xs: -2, lg: "auto"}, mt: {xs: 6, lg: 0}}}>
                    <Stack>
                        {
                            lang.getTranslation(thisId, "items").map((d, i) => (
                                <AboutUsOption
                                    icon="mediation"
                                    content={
                                        <>
                                            It becomes harder for us to give others a hand.
                                            <br/>
                                            We get our heart broken by people we love.
                                        </>
                                    }
                                />
                            ))
                        }
                    </Stack>
                </Grid>
            </Grid>
        </GridContainer>
    );
}

export default ProductOverview;

